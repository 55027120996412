<template>
  <main>
    <div class="product_container">
      <section class="section_header">
        <h1>상품 선택하기</h1>
        <div class="product_header_contents">
          <div>
            <span>1</span>
            <p>상품 선택하기</p>
          </div>
          <div>
            <span>2</span>
            <p></p>
          </div>
          <div>
            <span>3</span>
            <p></p>
          </div>
        </div>
      </section>
      <section class="section_body">
        <h3>요금제 선택</h3>
        <div class="description">
          <p>
            스탬프 팝 플랫폼 이용기간과 필요한 스탬프 수를 선택하시면 결제
            금액을 확인 하실 수 있습니다. 결제할 금액을 확인하신 후 결제하기
            버튼을 눌러주세요. <br />무통장입금은 오른쪽 버튼을 눌러 안내 사항을
            확인해주세요.
          </p>
          <button type="button" @click="deskDepositInfo">
            무통장입금 안내
            <img
              class="rectangle_37"
              src="@/assets/img/rectangle-37.png"
              alt="rectangle-37"
            />
          </button>
          <button type="button" @click="mobileDepositInfo">
            무통장입금 안내
            <img
              class="rectangle_37-black"
              src="@/assets/img/rectangle-37-black.png"
              alt="rectangle-37"
            />
          </button>
        </div>
        <div class="select_product_wrap">
          <div class="select_product">
            <div class="select_zone">
              <div class="select_box">
                <p>운영기간</p>
                <base-select :options="operating.day" v-model="operatingDay" />
              </div>
              <div class="select_box">
                <p>스탬프 수</p>
                <base-select
                  v-if="operatingDay != 365"
                  :options="operating.count"
                  v-model="operatingCount"
                />
                <div v-else><p>10 개</p></div>
              </div>
            </div>
            <div class="calc_zone">
              <div class="amount">
                <span>합계금액</span>
                <div v-if="form.id != 0 && operatingDay != 365">
                  {{ $n(form.originPrice) }}원 <span>(vat별도)</span>
                </div>
                <div v-else-if="form.id == 0 && operatingDay != 365">
                  별도문의
                </div>
                <div v-else-if="operatingDay == 365">
                  21,000,000원 <span>(vat별도)</span>
                </div>
              </div>
              <div class="discount">
                <span>할인</span>
                <div v-if="form.discountRate != 0 && operatingDay != 365">
                  -200,000원 <span>(vat별도)</span>
                </div>
                <div v-else-if="operatingDay == 365">
                  -16,000,000원 <span>(vat별도)</span>
                </div>
                <div v-else-if="form.id == 0">별도문의</div>
                <div v-else>-</div>
              </div>
              <div class="total">
                <span>결제할 금액</span>
                <div v-if="form.id != 0 && operatingDay != 365">
                  {{ $n(form.amount) }}원 <span>(vat별도)</span>
                </div>
                <div v-else-if="form.id == 0 && operatingDay != 365">
                  별도문의
                </div>
                <div v-else-if="operatingDay == 365">
                  5,000,000원 <span>(vat별도)</span>
                </div>
              </div>
            </div>
          </div>
          <div class="btns">
            <button @click="sendEmail">입점 문의</button>
            <button @click.prevent.stop="orderProduct">
              스탬프 팝 입점하기
            </button>
          </div>
        </div>

        <div class="services_container">
          <h3>제공 서비스 안내</h3>
          <ul class="services">
            <li class="service">
              <div class="img_box">
                <img src="@/assets/img/enrollment.png" alt="축제 정보 등록" />
              </div>
              <div class="text_box">
                <h4>축제 정보 등록</h4>
                <p>
                  스탬프 팝 앱 내<br class="br" />
                  축제/행사 정보 노출
                </p>
              </div>
            </li>
            <li class="service">
              <div class="img_box">
                <img src="@/assets/img/flatform.png" alt="간편한 플랫폼 임대" />
              </div>
              <div class="text_box">
                <h4>간편한 플랫폼 임대</h4>
                <p>
                  별도의 앱을 제작하지 않고<br class="br" />
                  스탬프투어 운영 가능
                </p>
              </div>
            </li>
            <li class="service">
              <div class="img_box">
                <img src="@/assets/img/stamp.png" alt="스탬프 10개 등록" />
              </div>
              <div class="text_box">
                <h4>스탬프/미션 리뷰 수집</h4>
                <p>
                  리뷰 기능이 있어 방문객<br class="br" />
                  반응을 쉽게 확인 가능
                </p>
              </div>
            </li>
            <li class="service">
              <div class="img_box">
                <img src="@/assets/img/design.png" alt="스탬프 디자인 제작" />
              </div>
              <div class="text_box">
                <h4>필요한 만큼 스탬프 등록</h4>
                <p>
                  관광지(축제) 내 획득할 스탬프를 <br class="br" />
                  필요한 수 만큼 등록 가능
                </p>
              </div>
            </li>
          </ul>
          <ul class="services">
            <li class="service">
              <div class="img_box">
                <img src="@/assets/img/gps.png" alt="다양한 스탬프 획득 방식" />
              </div>
              <div class="text_box">
                <h4>다양한 스탬프 획득 방식</h4>
                <p>
                  GPS와 QR코드로<br class="br" />
                  스탬프 획득 가능
                </p>
              </div>
            </li>
            <li class="service">
              <div class="img_box">
                <img
                  src="@/assets/img/mission.png"
                  alt="미션 및 리워드 제공 기능"
                />
              </div>
              <div class="text_box">
                <h4>미션 및 리워드 제공 기능</h4>
                <p>
                  미션 2개까지 설정 및<br class="br" />
                  리워드 제공방식 선택 가능<br class="br" />
                  (현장지급/우편수령)
                </p>
              </div>
            </li>
            <li class="service">
              <div class="img_box">
                <img src="@/assets/img/statistics.png" alt="이용 통계 제공" />
              </div>
              <div class="text_box">
                <h4>이용 통계 제공</h4>
                <p>
                  월 별 스탬프 별,<br class="br" />
                  리워드 수령 수 통계 제공
                </p>
              </div>
            </li>
            <li class="service">
              <div class="img_box">
                <img src="@/assets/img/promotion.png" alt="블로그 홍보" />
              </div>
              <div class="text_box">
                <h4>블로그 홍보</h4>
                <p>
                  스탬프 팝 블로그 내<br class="br" />
                  무료 홍보 진행
                </p>
              </div>
            </li>
          </ul>
        </div>
      </section>
    </div>
    <section class="inquiry_wrapper">
      <div class="inquiry">
        <h1>스탬프 팝 입점 문의</h1>
        <h1>
          <a href="tel:02-6337-0310">02-6337-0310</a>이나
          <span class="email" @click="sendEmail"
            >festival@nextinnovation.kr</span
          >으로<br class="br" />
          연락주시면 친절히 상담해드리겠습니다<span>.</span>
        </h1>
      </div>
    </section>
    <section class="appdown_wrapper">
      <div class="link_box">
        <div class="link_box_bg">
          <div class="left"></div>
          <div class="right"></div>
        </div>
        <div class="links_container">
          <div class="download">
            <h3>스탬프 팝 앱 다운받기</h3>
            <div class="app_icon">
              <img src="@/assets/img/app-icon.png" alt="스탬프 팝 앱" />
            </div>
            <div class="links">
              <a
                href="https://apps.apple.com/kr/app/스탬프팝-스탬프투어로-떠나는-국내여행/id1207835232"
                target="_blank"
                class="link"
              >
                <p>앱스토어 다운로드</p>
                <img src="@/assets/img/apple.png" alt="앱스토어 다운로드" />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=kr.nextinnovation.stamppop"
                target="_blank"
                class="link"
              >
                <p>구글스토어 다운로드</p>
                <img src="@/assets/img/google.png" alt="구글스토어 다운로드" />
              </a>
            </div>
          </div>
          <div class="question">
            <h3>스탬프 팝 입점 문의하기</h3>
            <div class="chat" @click.prevent.stop="onChannelIO">
              <h4>채팅 문의하기</h4>
              <p>클릭하시면 채팅문의로 바로 연결됩니다.</p>
              <img src="@/assets/img/chatbot.png" alt="채팅 문의하기" />
            </div>
            <div class="email" @click="sendEmail">
              <h4>이메일 문의하기</h4>
              <p>festival@nextinnovation.kr</p>
              <img src="@/assets/img/mail@2x.png" alt="이메일 문의하기" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import client from "api-client";
import Dialog from "@/components/Dialog.vue";
import DeskDepositInfo from "@/components/DeskDepositInfo.vue";
import MobileDepositInfo from "@/components/MobileDepositInfo.vue";
import BaseSelect from "@/components/core/BaseSelect.vue";
export default {
  components: {
    BaseSelect,
  },
  data() {
    return {
      submitStatus: null, // ERROR, PENDING, OK
      form: {
        id: 0,
        name: "",
        amount: 0,
        originPrice: 0,
        discountRate: 0,
        operatingCount: 0,
        operatingDay: 0,
      },
      customItem: {
        id: 0,
        name: "",
        amount: 0,
        originPrice: 0,
        discountRate: 0,
        operatingCount: 0,
        operatingDay: 0,
      },
      items: [],
      productId: 0,
      payple: {
        cpay_ver: "1.0.1",
        pay_type: "card",
        pay_work: "PAY",
      },
      operating: {
        // 추후 동적으로 변환될수 있기 때문에 변수 처리
        dayMin: 0,
        dayMax: 31,
        day: [],
        countMin: 0,
        countMax: 21,
        count: [],
      },
      operatingDay: 0,
      operatingCount: 0,
      meta: {
        title: "스탬프 투어, 스탬프 팝 - 입점 비용",
        description:
          "스탬프 투어는 스탬프 팝! 어떻게 하면 우리 관광지를 성공시킬까? 합리적인 비용으로 입점하세요. 기간별, 갯수별 셋팅 가능!",
      },
    };
  },
  computed: {
    ...mapGetters(["isAuthenticated"]),
  },
  created() {
    this.fetch();
  },
  watch: {
    operatingDay() {
      this.calculator();
    },
    operatingCount() {
      this.calculator();
    },
  },
  methods: {
    calculator() {
      let rate = 0;
      console.log(rate);

      // 사용자가 커스텀한 운영일수, 운영개수랑 동일한 상품이 있는지 체크
      this.form.id = 0;
      console.log("===============1");
      console.log(this.operatingDay);
      console.log(this.operatingCount);
      this.items.forEach((item) => {
        console.log("===============분기");
        console.log(item.operatingDay);
        console.log(item.operatingCount);
        if (
          Number(this.operatingCount) == item.operatingCount &&
          Number(this.operatingDay) == item.operatingDay
        ) {
          console.log("===============1 매칭");
          this.form.id = item.id;
          this.form.name = item.name;
          this.form.amount = item.amount;
          this.form.originPrice = item.originPrice;
          this.form.discountRate = item.discountRate;
          this.form.operatingCount = item.operatingCount;
          this.form.operatingDay = item.operatingDay;
        }
      });

      console.log("===============2");
      // 커스텀 상품
      if (this.form.id == 0) {
        this.form.id = this.customItem.id;
        this.form.name = this.customItem.name;
        this.form.amount = this.customItem.amount;
        this.form.originPrice = this.customItem.originPrice;
        this.form.discountRate = this.customItem.discountRate;
        this.form.operatingCount = this.customItem.operatingCount;
        this.form.operatingDay = this.customItem.operatingDay;

        // 커스텀 상품은 옵셥에 따라 계산을 한다.
        /**
         *
         * 기준 요금표
         * 기본세팅료 1,000,000
         * 운영 1일당 100,000
         * 스탬프 1개당 100,000
         *
         * 초과시 과금 기준 ( 15일 )
         * 운영 1일당 50,000
         * 스탬프 1개당 100,000
         *
         */

        if (
          366 <= Number(this.operatingDay) ||
          this.operating.countMax <= Number(this.operatingCount)
        ) {
          // 별도 문의
          this.form.id = 0;
          if (365 == Number(this.operatingDay)) {
            this.operatingCount = 8;
            this.form.operatingCount = 8;
          }
        } else {
          // 구매 가능
          let operatinDayPrice = 0;
          let operatinCountPrice = 0;

          operatinDayPrice = 100000 * Number(this.operatingDay);
          operatinCountPrice = 100000 * Number(this.operatingCount);

          // 할인전
          if (15 < Number(this.operatingDay)) {
            operatinDayPrice =
              operatinDayPrice - (Number(this.operatingDay) - 15) * 50000;
          }

          console.log("할인전 operatinDayPrice : " + operatinDayPrice);
          console.log("할인전 operatinCountPrice : " + operatinCountPrice);
          this.form.originPrice =
            1000000 + (operatinDayPrice + operatinCountPrice);

          if (12 <= Number(this.operatingCount)) {
            // 할인후(결제가)
            this.form.amount = this.form.originPrice - 200000;
            this.form.discountRate = Math.round(
              (200000 / this.form.originPrice) * 100
            );
          } else if (8 == Number(this.operatingCount)) {
            this.form.amount = this.form.originPrice;
          }

          // 1년권
          if (365 == Number(this.operatingDay)) {
            this.amount = 5000000;
            this.form.amount = 5000000;
            this.originPrice = 21000000;
            this.form.originPrice = 21000000;
          }
        }
      }
    },
    sendEmail() {
      window.open("mailto:festival@nextinnovation.kr");
    },
    orderProduct() {
      if (this.isAuthenticated) {
        this.$router.push({ name: "FestivalOrderProduct" });
      } else {
        this.$modal.show(
          Dialog,
          {
            type: "confirm2",
            title: "알림",
            content:
              "스탬프 팝 입점 신청을 위해 로그인해주세요.<br /> 아직 회원이 아닌 경우 회원가입을 해주세요.",
            trueText: "회원가입하기",
            falseText: "로그인하기",
          },
          {
            adaptive: true,
            width: "90%",
            maxWidth: 600,
            height: "auto",
          },
          {
            "before-open": () => {},
            "before-close": (response) => {
              if (response.params.confirm) {
                this.$router.push({ name: "Register" });
              } else {
                this.$router.push({ name: "Login" });
              }
            },
          }
        );
      }
    },
    deskDepositInfo() {
      this.$modal.show(
        DeskDepositInfo,
        {},
        {
          width: 620,
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    mobileDepositInfo() {
      this.$modal.show(
        MobileDepositInfo,
        {},
        {
          width: "100%",
          height: "auto",
        },
        {
          "before-open": () => {},
          "before-close": () => {},
        }
      );
    },
    fetch() {
      // 운영일수, 운영개수를 동적으로 불러올 경우를 대비해서 fetch 에서 변수 초기화를함.
      this.operating.dayMin = 5;
      this.operating.dayMax = 32;
      for (let i = this.operating.dayMin; i < this.operating.dayMax; i++) {
        if (
          i != 6 &&
          i != 7 &&
          i != 8 &&
          i != 9 &&
          i != 11 &&
          i != 12 &&
          i != 13 &&
          i != 14
        )
          this.operating.day.push({
            name: `${i} 일`,
            value: i,
          });
      }
      this.operating.day.push({
        name: `1년`,
        value: 365,
      });
      this.operating.day.push({
        name: `31일 초과`,
        value: 400,
      });

      this.operating.countMin = 8;
      this.operating.countMax = 21;
      for (let i = this.operating.countMin; i < this.operating.countMax; i++) {
        if (i != 9 && i != 10 && i != 11)
          this.operating.count.push({
            name: `${i} 개`,
            value: i,
          });
      }
      this.operating.count.push({
        name: `20개 초과`,
        value: 21,
      });

      let params = {
        sort: "amount.asc",
      };

      client.productFindAll(params).then((response) => {
        const { data } = response;
        data.items.forEach((item) => {
          if (item.operatingDay != 0 && item.amount != 0) {
            // 기본선택된 상품이 없음 ( 만약 메인에서 상품을 클릭해서 온다면 로직 변경 필요 ) > 상품 등록
            if (this.form.id == 0) {
              this.form.id = item.id;
              this.form.name = item.name;
              this.form.amount = item.amount;
              this.form.originPrice = item.originPrice;
              this.form.discountRate = item.discountRate;
              this.form.operatingCount = item.operatingCount;
              this.form.operatingDay = item.operatingDay;

              // 추후 deep watch 로 변경시 제거
              this.operatingCount = item.operatingCount;
              this.operatingDay = item.operatingDay;
            }

            this.items.push(item);
          }

          // 커스텀 상품
          if (
            item.operatingDay == 0 &&
            item.operatingCount == 0 &&
            item.amount == 0
          ) {
            this.customItem = item;
          }
        });
        console.log(this.items);
      });
    },
    onChannelIO() {
      document.getElementById("show_chatbot").click();
    },
  },
};
</script>
<style lang="scss">
@import "./index.scss";
</style>
